import React from 'react';

const footerStyle = {
  backgroundColor: 'transparent',
  color: '#4b4848',
  textAlign: 'center'
};

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>Software Developer</p>
      <p>&copy; 2024 Daniel Medina. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
