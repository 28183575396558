import React from 'react';
import pcGif from '../assets/pcgif.gif';

const About = () => {
  return (
    <div className="p-6 bg-transparent rounded-lg shadow-lg">
      <section id="about" className="flex flex-col md:flex-row items-center pt-6 pb-0">
        <div className="flex-1 flex items-center justify-center md:w-1/2 mt-4 md:mt-0">
          <img 
            src={pcGif} 
            alt="GIF del PC" 
            className="w-auto h-auto"
          />
        </div>
        <div className="flex-1 flex flex-col md:w-1/2">
          <div>
            <h2 className="text-3xl font-bold text-pastel-yellow mb-4">
              Sobre Mí
            </h2>
          </div>
          <div className="mt-6">
            <p className="text-lg text-gray-200">
              Desarrollador de aplicaciones multiplataforma, realizo tareas Back-end, Front-end o Full-stack sin problema. 
              Muchas veces me dedico a investigar nuevas tecnologías que aprender.
              También soy técnico informático con lo cual soy multifunción.
              Tengo muy presente la auto-mejora y desarrollo personal afrontando nuevos retos.
              El trabajo en equipo es fundamental, a si que durante años he mejorado mi capacidad adaptativa a un grupo de trabajo.
              Siempre estoy abierto a ofertas o propuestas, no dudes en contactarme.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
