import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  const [language, setLanguage] = useState('Español');
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const menuItems = [
    { name: 'Inicio', to: 'inicio' },
    { name: 'Sobre mi', to: 'sobre-mi' },
    { name: 'Tecnologías', to: 'tecnologias' },
    { name: 'Proyectos', to: 'proyectos' },
    { name: 'Contacto', to: 'contacto' }
  ];

  return (
    <header className="bg-custom-bg shadow-md p-4 flex justify-between items-center">
      <div className="flex items-center">
        <img src="/images/navico.png" alt="Logo" className="w-10 h-10" />
      </div>
      <nav className="hidden md:flex space-x-4">
        {menuItems.map((item) => (
          <ScrollLink
            key={item.name}
            to={item.to}
            smooth={true}
            duration={500}
            className={`text-gray-300 hover:text-pastel-yellow relative cursor-pointer ${activeLink === item.name ? 'text-pastel-yellow' : ''}`}
            onMouseEnter={() => setActiveLink(item.name)}
            onMouseLeave={() => setActiveLink('')}
            onSetActive={() => setActiveLink(item.name)}
          >
            {item.name}
            <span
              className={`absolute bottom-0 left-0 w-full h-0.5 bg-pastel-yellow transition-all duration-300 transform ${
                activeLink === item.name ? 'scale-x-100' : 'scale-x-0'
              }`}
            ></span>
          </ScrollLink>
        ))}
      </nav>
      <div className="relative">
        <button //QUITAR EL HIDDEN CUANDO ESTE TODO TRADUCIDO
          className="hidden text-gray-300 bg-gray-600 px-4 py-2 rounded-md"
          onClick={() => setIsOpen(!isOpen)}
        >
          {language}
        </button>
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-custom-bg border border-gray-600 rounded-md shadow-lg">
            <ul className="py-1">
              <li>
                <button
                  onClick={() => { setLanguage('English'); setIsOpen(false); }}
                  className="block px-4 py-2 text-gray-300 hover:bg-gray-700 w-full text-left"
                >
                  English
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setLanguage('Español'); setIsOpen(false); }}
                  className="block px-4 py-2 text-gray-300 hover:bg-gray-700 w-full text-left"
                >
                  Español
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setLanguage('Korean'); setIsOpen(false); }}
                  className="block px-4 py-2 text-gray-300 hover:bg-gray-700 w-full text-left"
                >
                  Korean
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
