import React from 'react';
import saludoImg from '../assets/manosaludo.png';
import extraImg from '../assets/wa3.jpeg';
import { TypeAnimation } from 'react-type-animation';

const Home = () => {
  return (
    <div className="p-6 bg-transparent rounded-lg shadow-lg">
      <section id="home" className="flex flex-col md:flex-row items-center justify-between pt-16 pb-0">
        <div className="flex-1 flex flex-col mr-6">
          <div className="flex items-center">
            <h2 className="text-3xl font-bold text-pastel-yellow mb-2 pt-1">
              Bienvenidos a mi portfolio
            </h2>
            <img 
              src={saludoImg} 
              alt="Manos saludando" 
              className="object-contain h-auto ml-4"
              style={{ width: 'auto', height: '50px' }}
            />
          </div>
          <div className="flex flex-col mt-6">
            <h1 className="text-7xl font-extrabold text-gray-200">
              Daniel Medina
            </h1>
            <div className="pt-6">
              <div className="h-32">
                <TypeAnimation
                  sequence={[
                    'Software Developer',
                    2000,
                    '',
                    1000,
                    'Enfocado al desarrollo Full-Stack',
                    2000,
                    '',
                    1000,
                  ]}
                  speed={50}
                  wrapper="h1"
                  className="text-4xl text-gray-300"
                  repeat={Infinity}
                />
              </div>
            </div>
            <div className="mt-2 flex">
              <a href="/DanielMedina_Resume_ES.pdf" download>
                <button className="relative flex items-center px-6 py-3 overflow-hidden font-medium transition-all bg-yellow-300 rounded-md group">
                  <span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-yellow-500 rounded group-hover:-mr-4 group-hover:-mt-4">
                    <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-gray-200"></span>
                  </span>
                  <span className="absolute bottom-0 rotate-180 left-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-yellow-500 rounded group-hover:-ml-4 group-hover:-mb-4">
                    <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-gray-200"></span>
                  </span>
                  <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-yellow-400 rounded-md group-hover:translate-x-0"></span>
                  <span className="relative w-full text-left text-gray-800 transition-colors duration-200 ease-in-out group-hover:text-gray-800">Descargar CV</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="flex-1 w-full mt-6 md:mt-0 relative">
          <div className="absolute inset-0 border-2 border-yellow-400 rounded-lg animate-pulse">
          </div>
          <img 
            src={extraImg} 
            alt="Imagen adicional" 
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      </section>
    </div>
  );
};

export default Home;
