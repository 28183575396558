import React from 'react';
import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Tech from './components/Tech';
import Home from './components/Home';
import About from './components/About';
import Proyects from './components/Projects';

function App() {
  return (
    <div className="App bg-custom-bg min-h-screen text-gray-300">
      <Header />
      <main className="p-4">
        <section id="inicio"><Home /></section>
        <section id="sobre-mi"><About /></section>
        <section id="tecnologias"><Tech /></section>
        <section id="proyectos"><Proyects /></section>
        <section id="contacto"><Contact /></section>
        <Footer />
      </main>
    </div>
  );
}

export default App;
