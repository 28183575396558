import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [buttonText, setButtonText] = useState('Enviar');
  const [copyButtonText, setCopyButtonText] = useState('Copiar');
  const [isSending, setIsSending] = useState(false);
  const [emailError, setEmailError] = useState(''); // Para mostrar errores de validación de email

  // Lista de dominios temporales comunes
  const temporaryEmailDomains = [
    '10minutemail.com', 'guerrillamail.com', 'mailinator.com', 'tempmail.com', 
    'yopmail.com', 'trashmail.com', 'fakeinbox.com', 'dispostable.com', 'getnada.com'
  ];

  // Validar si el correo es válido y si es de un dominio temporal
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domain = email.split('@')[1];
    if (!emailRegex.test(email)) {
      return 'Por favor, introduce un email válido.';
    }
    if (temporaryEmailDomains.includes(domain)) {
      return 'No se permiten correos temporales.';
    }
    return '';
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText('dm46688@gmail.com');
    setCopyButtonText('Copiado');
    setTimeout(() => setCopyButtonText('Copiar'), 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar email al hacer submit
    const emailValidationError = validateEmail(formData.email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    setIsSending(true);
    setButtonText('Enviando...');

    emailjs.send(
      'service_t5fiyid',
      'template_628ksxs',
      formData,
      'AidfaKr347bWPvf_6'
    )
    .then((result) => {
      setButtonText('Mensaje Enviado');
      setTimeout(() => {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setButtonText('Enviar');
        setIsSending(false);
        setEmailError(''); // Limpiar el error si el envío es exitoso
      }, 2000);
    }, (error) => {
      console.error('Hubo un problema al enviar el mensaje:', error);
      setButtonText('Error');
      setTimeout(() => {
        setButtonText('Enviar');
        setIsSending(false);
      }, 2000);
    });
  };

  return (
    <div className="p-6 bg-transparent rounded-lg shadow-md relative">
      <h2 className="text-3xl font-bold text-pastel-yellow mb-4">Contacto</h2>
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-start lg:items-start">
        
        <div className="w-full lg:w-2/3">
          <form onSubmit={handleSubmit} className="max-w-lg p-6 rounded-lg flex-grow lg:mr-8">
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-300">Nombre:</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                onChange={handleChange}
                value={formData.name}
                className="w-full px-3 py-2 border border-pastel-yellow rounded-md bg-neutral-800 text-gray-300"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-300">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                onChange={handleChange}
                value={formData.email}
                className="w-full px-3 py-2 border border-pastel-yellow rounded-md bg-neutral-800 text-gray-300"
              />
              {emailError && <p className="text-red-500 mt-2">{emailError}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="subject" className="block text-gray-300">Asunto:</label>
              <input
                type="text"
                id="subject"
                name="subject"
                required
                onChange={handleChange}
                value={formData.subject}
                className="w-full px-3 py-2 border border-pastel-yellow rounded-md bg-neutral-800 text-gray-300"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-300">Mensaje:</label>
              <textarea
                id="message"
                name="message"
                required
                rows="5"
                onChange={handleChange}
                value={formData.message}
                className="w-full px-3 py-2 border border-pastel-yellow rounded-md bg-neutral-800 text-gray-300"
              ></textarea>
            </div>
            <div className="flex justify-center lg:justify-start">
              <button
                type="submit"
                disabled={isSending}
                className={`bg-pastel-yellow text-gray-800 px-4 py-2 rounded-md hover:bg-yellow-400 transition-all duration-300 ${isSending ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>

        <div className="w-full lg:w-1/3 lg:ml-8 mt-8 lg:mt-0 flex flex-col items-center lg:items-end lg:absolute lg:bottom-4 lg:right-4 gap-4">
          <div className="flex gap-4">
            <a
              href="https://www.linkedin.com/in/danim299/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pastel-yellow hover:text-yellow-400 transition-colors duration-300"
            >
              <i className="fab fa-linkedin-in fa-2x"></i>
            </a>
            <a
              href="https://github.com/Danim299"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pastel-yellow hover:text-yellow-400 transition-colors duration-300"
            >
              <i className="fab fa-github fa-2x"></i>
            </a>
          </div>
          <div className="text-pastel-yellow flex items-center gap-2">
            <span>dm46688@gmail.com</span>
            <button
              onClick={copyToClipboard}
              className="bg-pastel-yellow text-gray-800 px-2 py-1 rounded-md hover:bg-yellow-400 transition-all duration-300"
            >
              {copyButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
