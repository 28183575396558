import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Projects.css';

const projects = [
  {
    id: 1,
    title: 'Portfolio',
    description: 'Portfolio hecho con React y Tailwind',
    imageUrl: '/portfolioimg.png'
  },
  {
    id: 2,
    title: 'Bot Discord',
    description: 'Bot multifuncional, que destaca por hacer copias de seguridad de un canal del servidor y guardarlos en archivos JSON para luego poder recuperarlos. El proyecto está realizado en Python',
    imageUrl: '/botbackup.png'
  },
  {
    id: 3,
    title: 'Mazmorra',
    description: 'Proyecto final 1 DAM. Consiste en un juego de decisiones, adentrandote en mazmorras donde va a influir los objetos que lleves contigo. Hecho en Java',
    imageUrl: '/mazimg.png'
  },
  {
    id: 4,
    title: 'Calculadora',
    description: 'Calculadora hecha en kotlin',
    imageUrl: '/calcimg.png'
  },
  {
    id: 5,
    title: 'Juego Tablero',
    description: 'Juego de tablero estilo OCA, diseñado para niños de 4 ESO, abarcando la asignatura de historia. Hecho en Java',
    imageUrl: '/tableroimg.png'
  },
  {
    id: 6,
    title: 'Biblioteca digital',
    description: 'Esta aplicación tiene la misma función que cualquier biblioteca real mediante una interfaz hecha con las librerias tkinter. El proyecto está hecho en su totalidad con Python',
    imageUrl: '/dinalib.png'
  }
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};

const Projects = () => {
  return (
    <div className="p-6 bg-transparent rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-pastel-yellow mb-4">Proyectos</h2>
      <Slider {...settings}>
        {projects.map((project) => (
          <div key={project.id} className="px-2">
            <div className="relative">
              <img src={project.imageUrl} alt={project.title} className="w-full h-64 object-cover rounded-lg" />
              <div className="absolute bottom-0 left-0 right-0 project-text-container">
                <h3 className="text-xl font-semibold">{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Projects;
